import * as React from 'react'

type Props = {
  name: string
} & React.HTMLAttributes<HTMLSpanElement>
const TagComponent = ({name, ...props}: Props) => {
  return <span className="post-tag" {...props}>{name}</span>
}

export default TagComponent
