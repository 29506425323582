import { Link } from 'gatsby'
import * as React from 'react'
import cx from 'classnames'
import { useMemo } from 'react'

interface ButtonProps extends React.HtmlHTMLAttributes<HTMLButtonElement> {
  as: 'button'
}

interface AnchorProps extends React.HtmlHTMLAttributes<HTMLAnchorElement> {
  as: 'a'
  to: string,
}

interface CommonButtonProps {
  buttonColor?: 'primary' | 'light'
}

const ButtonComponent = ({as, children, className, buttonColor, ...props}: (ButtonProps | AnchorProps) & CommonButtonProps) => {
  const buttonColorClass = useMemo(() => {
    switch (buttonColor) {
    case 'primary':
      return 'button--color-primary'
    case 'light':
      return 'button--color-light'
    default:
      return 'button--color-primary'
    }
  }, [buttonColor])

  const combinedButtonClasses = useMemo<Array<undefined | string>>(() => {
    return [className, 'button', buttonColorClass]
  }, [className, buttonColorClass, buttonColorClass])

  switch (as) {
  case 'a':
    return <Link className={cx(combinedButtonClasses)} {...props as AnchorProps}>{children}</Link>
  case 'button':
    return <button className={cx(combinedButtonClasses)} {...props as React.HtmlHTMLAttributes<HTMLButtonElement>}>{children}</button>
  default:
    throw `ButtonComponent: unsupported element '${as}'`
  }
}

export default ButtonComponent
