import * as React from 'react'
import MainLayout from './main-layout'
import { useMemo, useLayoutEffect, useCallback } from 'react'
import { Helmet } from 'react-helmet'
import MdxProviderComponent from '../components/molecules/mdx-provider/mdx-provider-component'

const PostLayout = ({ children, pageContext, ...props }: any) => {
  const scrollToAnchor = useCallback((anchor: string) => {
    if (anchor) {
      const title = document.querySelector(`[data-anchor="${anchor}"]`)
      const navigation = document.querySelector('#main-navigation')
      let offset = 0
      if (navigation) {
        offset =
          navigation.getBoundingClientRect().height +
          navigation.getBoundingClientRect().y
      }

      if (title) {
        setTimeout(() => {
          window.scroll(
            0,
            title.getBoundingClientRect().y - offset + window.scrollY
          )
        }, 1)
      }
    }
  }, [])

  const pageTitle = useMemo<string>(() => {
    const title = pageContext?.frontmatter?.title
    if (!title) {
      return 'Max Bause - Blog'
    }

    return `Max Bause - ${title}`
  }, [pageContext])

  useLayoutEffect(() => {
    const { hash } = new URL(window.location.href)
    scrollToAnchor(hash.slice(1))
  }, [scrollToAnchor])
  
  return (
    <MainLayout>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="post-layout__content">
        <MdxProviderComponent scrollToAnchor={scrollToAnchor} pageContext={pageContext} {...props}>{children}</MdxProviderComponent>
      </div>
    </MainLayout>
  )
}

export default PostLayout
