import * as React from 'react'
import '@fontsource/ibm-plex-serif/100.css'
import '@fontsource/ibm-plex-serif/300.css'
import '@fontsource/ibm-plex-serif/700.css'
import '../scss/index.scss'
import NavigationComponent from '../components/molecules/navigation/navigation-component'
import { createContext, useEffect, useState, useCallback } from 'react'
import { Helmet } from 'react-helmet'
import FooterComponent from '../components/molecules/footer/footer-component'
import cx from 'classnames'
import TagManager from 'react-gtm-module'
import CookieBannerComponent from '../components/molecules/cookie-banner/cookie-banner-component'

type Props = {
  children: any
  className?: string
} & React.HTMLAttributes<HTMLElement>

type Theme = 'dark' | 'light'
type FontSize = 'normal' | 'big'
type ThemeContext = {
  theme: Theme
  fontSize: FontSize
  setTheme: (x: Theme) => void
  setFontSize: (x: FontSize) => void
}

export const ThemeContext = createContext<ThemeContext>({
  theme: 'light',
  fontSize: 'normal',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  setTheme: (x) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  setFontSize: (x) => {},
})

const MainLayout = ({ children, className, ...props }: Props) => {
  const [theme, setTheme] = useState<Theme>('light')
  const [fontSize, setFontSize] = useState<FontSize>('normal')

  const onOsThemeChange = useCallback((event: MediaQueryListEvent) => {
    setAndSaveTheme(event.matches ? 'dark' : 'light')
  }, [])

  const setAndSaveTheme = useCallback((newThemeState: Theme) => {
    window.localStorage.setItem('theme', newThemeState)
    setTheme(newThemeState)
  }, [])

  const setAndSaveFontSize = useCallback((newFontSizeState: FontSize) => {
    window.localStorage.setItem('fontSize', newFontSizeState)
    setFontSize(newFontSizeState)
  }, [])

  useEffect(() => {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', onOsThemeChange)
    setTheme((window.localStorage.getItem('theme') as Theme) || 'light')
    setFontSize((window.localStorage.getItem('fontSize') as FontSize) || 'normal')

    return () => {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', onOsThemeChange)
    }
  }, [])

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: process.env.GATSBY_GTM_ID || '',
    }
   
    if (process.env.GATSBY_ENABLE_GTM == 'true') { 
      TagManager.initialize(tagManagerArgs)
    }
  }, [])

  return (
    <ThemeContext.Provider
      value={{
        theme,
        fontSize,
        setTheme: (x: Theme) => setAndSaveTheme(x),
        setFontSize: (x: FontSize) => setAndSaveFontSize(x),
      }}
    >
      <Helmet
        bodyAttributes={{
          'data-theme': theme,
          'data-font-size': fontSize,
        }}
      >
        <title>Max Bause - Blog</title>
        <script src="/libs/pace.js/pace.min.js"></script>
        <link rel="stylesheet" href="/libs/pace.js/pace-theme-default.min.css"></link>
      </Helmet>
      <main className={cx('main-layout', className)} {...props}>
        <div className="main-layout__inner">
          <NavigationComponent />
          <div style={{ height: '1.6rem' }}></div>
          {children}
        </div>
      </main>
      <FooterComponent />
      <CookieBannerComponent />
    </ThemeContext.Provider>
  )
}

export default MainLayout
