import { BiMoon } from '@react-icons/all-files/bi/BiMoon'
import { BiSun } from '@react-icons/all-files/bi/BiSun'
import * as React from 'react'
import { useContext } from 'react'
import { ThemeContext } from '../../../layouts/main-layout'

const ThemeToggleComponent = () => {
  const themeContext = useContext(ThemeContext)

  return (
    <div
      className="theme-toggle"
      onClick={() =>
        themeContext.setTheme(themeContext.theme == 'light' ? 'dark' : 'light')
      }
    >
      {themeContext.theme == 'dark' ? <BiMoon /> : <BiSun />}
      <div className="theme-toggle__background"></div>
    </div>
  )
}

export default ThemeToggleComponent
