import { graphql, Link, useStaticQuery } from 'gatsby'
import * as React from 'react'
import { useMemo } from 'react'

const FooterComponent = () => {
  const rawVersionInfo = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "build-info.mdx" }) {
        childMdx {
          rawBody
        }
      }
    }
  `)

  const versionInfo = useMemo<{ version: string; commit: string }>(() => {
    let version = ''
    let commit = ''

    const queryData = rawVersionInfo?.file?.childMdx?.rawBody
    if (queryData) {
      [version, commit] = queryData.split('\n').filter(Boolean)
    }

    return { version, commit }
  }, [rawVersionInfo])

  return (
    <footer className="footer">
      <div className="footer__inner">
        <span>
          Build with ☕️ and <Link to="/stack">this</Link> tech stack.
          <br />v{versionInfo.version} ({versionInfo.commit})
          <br /> © {new Date().getFullYear()} Max Bause
        </span>
      </div>
    </footer>
  )
}

export default FooterComponent
