import * as React from 'react'
import ButtonComponent from '../../atoms/button/button-component'
import { useMemo, useEffect, useState, useCallback } from 'react'
import cx from 'classnames'
import Cookies from 'js-cookie'

const CookieBannerComponent = () => {
  const cookieBannerVersion = useMemo<number>(() => 1, [])
  const [showBanner, setShowBanner] = useState<boolean>(false)

  const setCookieChoice = useCallback((choice: 'accepted' | 'rejected') => {
    window.localStorage.setItem(`cookie-banner-${cookieBannerVersion}`, choice);
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({event: 'cookie_choice_set'})
  }, [])

  const getCookieChoice = useCallback(() => {
    return window.localStorage.getItem(`cookie-banner-${cookieBannerVersion}`)
  }, [])

  const onCookiesAccepted = useCallback(() => {
    Cookies.set('cookie-banner-accept-google-analytics', 'true')
    setCookieChoice('accepted')
    setShowBanner(false)
  }, [])

  const onCookiesRejected = useCallback(() => {
    Cookies.set('cookie-banner-accept-google-analytics', 'false')
    setCookieChoice('rejected')
    setShowBanner(false)
  }, [])

  useEffect(() => {
    if (!getCookieChoice()) {
      setShowBanner(true)
    }
  }, [])

  return (
    <div className={cx('cookie-banner', {'cookie-banner--show': showBanner})}>
      <div className="cookie-banner__content">
        <h4 className="mb-1">🍪 Cookies</h4>
        <p className="mb-2">This site uses Google Analytics to create the best possible user experience.</p>
        <div>
          <ButtonComponent as="button" className="mr-1" onClick={onCookiesAccepted}>Accept cookies</ButtonComponent>
          <ButtonComponent as="button" buttonColor="light" onClick={onCookiesRejected}>Reject</ButtonComponent>
        </div>
      </div>
      <div className="cookie-banner__background"></div>
    </div>
  )
}

export default CookieBannerComponent
