import { Link } from 'gatsby'
import * as React from 'react'
import { useState } from 'react'
import ThemeToggleComponent from '../../atoms/theme-toggle/theme-toggle-component'
import { BiMenu } from '@react-icons/all-files/bi/BiMenu'
import cx from 'classnames'
import FontSizeToggleComponent from '../../atoms/font-size-toggle/font-size-toggle-component'

const NavigationComponent = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false)

  return (
    <div id="main-navigation" className="main-navigation">
      <Link to="/" className="main-navigation__logo">
        Max Bause
      </Link>
      <div
        onClick={() => setShowMenu(!showMenu)}
        className={cx('main-navigation__background', {
          'main-navigation__background--active': showMenu,
        })}
      ></div>
      <div className="main-navigation__menu">
        <div
          className={cx('main-navigation__menu-items', {
            'main-navigation__menu-items--active': showMenu,
          })}
        >
          <Link
            to="/posts"
            className={cx('main-navigation__menu-item', {
              'main-navigation__menu-item--active':
                typeof location !== 'undefined' && !!location.pathname.match(/^\/posts/g),
            })}
          >
            Posts
          </Link>
          <Link
            to="/contact"
            className={cx('main-navigation__menu-item', {
              'main-navigation__menu-item--active':
              typeof location !== 'undefined' && !!location.pathname.match(/^\/contact/g),
            })}
          >
            Contact
          </Link>
        </div>
        <FontSizeToggleComponent />
        <ThemeToggleComponent />
        <div
          className={cx('main-navigation__burger', {
            'main-navigation__burger--active': showMenu,
          })}
          onClick={() => setShowMenu(!showMenu)}
        >
          <BiMenu />
          <div
            className={cx('main-navigation__burger-background', {
              'main-navigation__burger-background--active': showMenu,
            })}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default NavigationComponent
