import * as React from 'react'
import {useMemo} from 'react'
import TagComponent from '../../atoms/tag/tag-component'
import cx from 'classnames'

type Props = {
  tagNames: string[]
  className?: string
} & React.HtmlHTMLAttributes<HTMLDivElement>
const TagListComponent = ({tagNames, className, ...props}: Props) => {
  const tags = useMemo(() => {
    return tagNames?.map((tag, index) => {
      return <TagComponent key={index} name={tag} />
    })
  }, [tagNames])

  return <div className={cx('tag-list', className)} {...props}>{tags}</div>
}

export default TagListComponent
