import * as React from 'react'
import Highlight, { defaultProps } from 'prism-react-renderer'
import lightTheme from 'prism-react-renderer/themes/nightOwlLight'
import darkTheme from 'prism-react-renderer/themes/nightOwl'
import { useContext } from 'react'
import { ThemeContext } from '../../../layouts/main-layout'
import cx from 'classnames'
import { v4 } from 'uuid'

const CodeBlockComponent = ({ children, className, showLineNumbers, title, ...props }: any) => {
  const language = className.replace(/language-/, '') || ''
  const themeContext = useContext(ThemeContext)
  return (
    <Highlight
      key={v4()}
      {...defaultProps}
      code={children}
      language={language}
      theme={themeContext.theme == 'light' ? lightTheme : darkTheme}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre className={cx(className, 'code-block')} style={style}>
          { title ?
            <div className="code-block__title">{title}</div> : null
          }
          <div className="code-block__block">
            {showLineNumbers ? (
              <div className="code-block__line-numbers">
                {tokens.slice(0, tokens.length - 1).map((_, i) => (
                  <span className="code-block__line-number" key={i}>
                    {i + 1}
                  </span>
                ))}
              </div>
            ) : null}
            <div className="code-block__code-lines">
              {tokens.slice(0, tokens.length - 1).map((line, i) => (
                <div key={i} {...getLineProps({ line, key: i })}>
                  {line.map((token, key) => (
                    <span key={key} {...getTokenProps({ token, key })} />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </pre>
      )}
    </Highlight>
  )
}

export default CodeBlockComponent
