import * as React from 'react'
import DateHelper from '../../../helpers/date-helper'
import TagListComponent from '../tag-list/tag-list-component'

type Props = {
  date: string
  description: string
  path: string
  tags: string[] | null
  title: string
}
const PostMetaComponent = ({tags, date}: Props) => {
  return <div className='post-meta'>
    <span className='m-0 mb-1 mr-1 t-tiny'>Published on {DateHelper.toUiDateString(date)}</span>
    {tags ? <TagListComponent tagNames={tags}/> : null }
  </div>
}

export default PostMetaComponent
