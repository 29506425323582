import { BiFontSize } from '@react-icons/all-files/bi/BiFontSize'
import * as React from 'react'
import { useContext } from 'react'
import { ThemeContext } from '../../../layouts/main-layout'
import cx from 'classnames'

const FontSizeToggleComponent = () => {
  const themeContext = useContext(ThemeContext)

  return (
    <div
      className={cx('font-size-toggle', {
        'font-size-toggle--active': themeContext.fontSize === 'big',
      })}
      onClick={() => {
        themeContext.setFontSize(
          themeContext.fontSize == 'normal' ? 'big' : 'normal'
        )
      }}
    >
      <BiFontSize />
      <div className="font-size-toggle__background"></div>
    </div>
  )
}

export default FontSizeToggleComponent
