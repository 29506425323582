import * as React from 'react'
import {useMemo} from 'react'
import cx from 'classnames'
import { GoFlame} from '@react-icons/all-files/go/GoFlame'
import { GoInfo } from '@react-icons/all-files/go/GoInfo'
import { GoAlert } from '@react-icons/all-files/go/GoAlert'
import MdxProviderComponent from '../mdx-provider/mdx-provider-component'
import Markdown from 'markdown-to-jsx'

type AlertComponentLevels = 'info' | 'warning' | 'danger'

type Props = {
  level?: AlertComponentLevels
  className?: string
  children?: any
} & React.HTMLAttributes<HTMLDivElement>

const AlertComponent = ({level, className, children, ...props}: Props) => {
  const iconComponent = useMemo(() => {
    switch (level) {
    case 'danger':
      return <GoFlame />
    case 'warning':
      return <GoAlert />
    default:
      return <GoInfo />
    }
  }, [level])

  return (
    <div className={cx('alert', `alert--${level || 'info'}`, className)} {...props}>
      <div className='alert__title-line'>{iconComponent} <span className='alert__title'>{level ||'info'}</span></div>
      <div className='alert__body'><MdxProviderComponent><Markdown>{children}</Markdown></MdxProviderComponent></div>
    </div>
  )
}

export default AlertComponent
