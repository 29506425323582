import Service from '../../service'

export type OpenGraphBuildServiceInputs = {
  title: string | undefined
  description: string | undefined
  path: string | undefined
  image: string | undefined
}

export interface OpenGraphElementInfo {
  htmlTag: 'title' | 'meta'
  content: string
}

export interface OpenGraphElementInfoTitle extends OpenGraphElementInfo {
  htmlTag: 'title'
}

export interface OpenGraphElementInfoMeta extends OpenGraphElementInfo {
  htmlTag: 'meta'
  name: string,
  nameKey: 'name' | 'property'
  contentKey: 'content'
}

export default class BuiltService extends Service {
  constructor(private inputs: OpenGraphBuildServiceInputs) {
    super()
  }

  public override execute(): Array<OpenGraphElementInfoTitle| OpenGraphElementInfoMeta> {
    const elements: Array<OpenGraphElementInfoTitle| OpenGraphElementInfoMeta> = []

    elements.push(...this.builtDefaultElements())
    elements.push(...this.builtFacebookElements())
    elements.push(...this.builtTwitterElements())

    return elements
  }

  private builtDefaultElements(): Array<OpenGraphElementInfoTitle| OpenGraphElementInfoMeta> {
    const elements: Array<OpenGraphElementInfoTitle| OpenGraphElementInfoMeta> = []

    if (this.inputs?.title) {
      elements.push({
        htmlTag: 'title',
        content: this.inputs.title
      })
    }

    if (this.inputs?.description) {
      elements.push({
        htmlTag: 'meta',
        content: this.inputs.description,
        contentKey: 'content',
        name: 'description',
        nameKey: 'name'
      })
    }

    return elements
  }

  private builtTwitterElements(): OpenGraphElementInfoMeta[] {
    const elements: OpenGraphElementInfoMeta[] = []

    elements.push({
      htmlTag: 'meta',
      content: 'summary_large_image',
      contentKey: 'content',
      name: 'twitter:card',
      nameKey: 'name'
    })

    elements.push({
      htmlTag: 'meta',
      content: process.env.GATSBY_HOST || 'maxbause.me',
      contentKey: 'content',
      name: 'twitter:domain',
      nameKey: 'name'
    })

    if (this.inputs?.path) {
      elements.push({
        htmlTag: 'meta',
        content: `https://${process.env.GATSBY_HOST}${this.inputs.path}`,
        contentKey: 'content',
        name: 'twitter:url',
        nameKey: 'name'
      })
    }

    if (this.inputs?.title) {
      elements.push({
        htmlTag: 'meta',
        content: this.inputs.title,
        contentKey: 'content',
        name: 'twitter:title',
        nameKey: 'name'
      })
    }

    if (this.inputs?.title) {
      elements.push({
        htmlTag: 'meta',
        content: this.inputs.title,
        contentKey: 'content',
        name: 'twitter:title',
        nameKey: 'name'
      })
    }

    if (this.inputs?.description) {
      elements.push({
        htmlTag: 'meta',
        content: this.inputs.description,
        contentKey: 'content',
        name: 'twitter:description',
        nameKey: 'name'
      })
    }

    if (this.inputs?.image) {
      elements.push({
        htmlTag: 'meta',
        content: this.inputs.image,
        contentKey: 'content',
        name: 'twitter:image',
        nameKey: 'name'
      })
    }

    return elements
  }

  private builtFacebookElements(): OpenGraphElementInfoMeta[] {
    const elements: OpenGraphElementInfoMeta[] = []

    elements.push({
      htmlTag: 'meta',
      content: 'website',
      contentKey: 'content',
      name: 'og:type',
      nameKey: 'property'
    })

    if (this.inputs?.title) {
      elements.push({
        htmlTag: 'meta',
        content: this.inputs.title,
        contentKey: 'content',
        name: 'og:title',
        nameKey: 'property'
      })
    }

    if (this.inputs?.description) {
      elements.push({
        htmlTag: 'meta',
        content: this.inputs.description,
        contentKey: 'content',
        name: 'og:description',
        nameKey: 'property'
      })
    }

    if (this.inputs?.image) {
      elements.push({
        htmlTag: 'meta',
        content: this.inputs.image,
        contentKey: 'content',
        name: 'og:image',
        nameKey: 'property'
      })
    }

    if (this.inputs?.path) {
      elements.push({
        htmlTag: 'meta',
        content: `https://${process.env.GATSBY_HOST}${this.inputs.path}`,
        contentKey: 'content',
        name: 'og:url',
        nameKey: 'property'
      })
    }

    return elements
  }
}
