import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useMemo } from 'react'
import Services from '../../../services'

type Props = {
  title?: string | undefined
  description?: string | undefined
  image?: string | undefined
  path?: string | undefined
}
const PostSeoComponent = ({ title, description, image, path }: Props) => {
  const metaElements = useMemo(() => {
    const elements: JSX.Element[] = []
    const props = {
      title: title ? `Max Bause - ${title}` : 'Max Bause - Blog',
      description: description || 'Welcome to my personal tech blog.',
      image: image || '/assets/images/og-default.png',
      path
    }

    new Services.Seo.OpenGraph.BuiltService(props).execute().forEach((element, index) => {
      if (element.htmlTag == 'title') {
        elements.push(React.createElement('title', {key: index}, element.content))
      } else {
        elements.push(React.createElement('meta', {key: index, [element.nameKey]: element.name, [element.contentKey]: element.content }))
      }
    })

    return elements
  }, [title, description, image, path])

  return <Helmet>
    {metaElements}
  </Helmet>
}

export default PostSeoComponent
