import * as React from 'react'
import { MDXProvider } from '@mdx-js/react'
import { useMemo } from 'react'
import CodeBlockComponent from '../code-block/code-block-component'
import LinkedTitleComponent from '../../atoms/linked-title/linked-title-component'
import AlertComponent from '../alert/alert-component'
import PostMetaComponent from '../post-meta/post-meta-component'
import PostSeoComponent from '../post-seo/post-seo-component'

type Props = {
  children: any,
  pageContext?: any,
  scrollToAnchor?: (anchor: string) => void
}

const MdxProviderComponent = ({ children, pageContext, scrollToAnchor }: Props) => {
  const components = useMemo(() => {
    return {
      pre: (props: any) => <div {...props}></div>,
      code: CodeBlockComponent,
      h1: (props: any) => (
        <LinkedTitleComponent
          element="h1"
          onAnchorClick={scrollToAnchor}
          {...props}
        />
      ),
      h2: (props: any) => (
        <LinkedTitleComponent
          element="h2"
          onAnchorClick={scrollToAnchor}
          {...props}
        />
      ),
      h3: (props: any) => (
        <LinkedTitleComponent
          element="h3"
          onAnchorClick={scrollToAnchor}
          {...props}
        />
      ),
      h4: (props: any) => (
        <LinkedTitleComponent
          element="h4"
          onAnchorClick={scrollToAnchor}
          {...props}
        />
      ),
      h5: (props: any) => (
        <LinkedTitleComponent
          element="h5"
          onAnchorClick={scrollToAnchor}
          {...props}
        />
      ),
      h6: (props: any) => (
        <LinkedTitleComponent
          element="h6"
          onAnchorClick={scrollToAnchor}
          {...props}
        />
      ),
      // eslint-disable-next-line react/prop-types
      PostMetaComponent: () => <PostMetaComponent {...pageContext?.frontmatter} />,
      AlertComponent: AlertComponent,
      PostSeoComponent: (props: any) => <PostSeoComponent {...pageContext?.frontmatter} path={pageContext?.frontmatter?.path ? `/posts/${pageContext.frontmatter.path}` : undefined} {...props}/>
    }
  }, [scrollToAnchor, pageContext])

  return <MDXProvider components={components}>{children}</MDXProvider>
}

export default MdxProviderComponent
