exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-mdx": () => import("./../../../src/pages/contact.mdx" /* webpackChunkName: "component---src-pages-contact-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-posts-2019-07-17-react-boilerplate-index-mdx": () => import("./../../../src/pages/posts/2019-07-17-react-boilerplate/index.mdx" /* webpackChunkName: "component---src-pages-posts-2019-07-17-react-boilerplate-index-mdx" */),
  "component---src-pages-posts-2019-08-05-css-shapes-index-mdx": () => import("./../../../src/pages/posts/2019-08-05-css-shapes/index.mdx" /* webpackChunkName: "component---src-pages-posts-2019-08-05-css-shapes-index-mdx" */),
  "component---src-pages-posts-2020-01-02-frontend-boilerplate-ts-next-gql-react-index-mdx": () => import("./../../../src/pages/posts/2020-01-02-frontend-boilerplate-ts-next-gql-react/index.mdx" /* webpackChunkName: "component---src-pages-posts-2020-01-02-frontend-boilerplate-ts-next-gql-react-index-mdx" */),
  "component---src-pages-posts-index-tsx": () => import("./../../../src/pages/posts/index.tsx" /* webpackChunkName: "component---src-pages-posts-index-tsx" */),
  "component---src-pages-stack-mdx": () => import("./../../../src/pages/stack.mdx" /* webpackChunkName: "component---src-pages-stack-mdx" */)
}

