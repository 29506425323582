import { Link } from 'gatsby'
import * as React from 'react'
import { useMemo, useCallback } from 'react'
import slugify from 'slugify'

type Props = {
  element: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  children: any
  onAnchorClick: (anchor: string) => void
}

const LinkedTitleComponent = ({ element, children, onAnchorClick }: Props) => {
  const niceId = useMemo<string>(() => {
    if (typeof children !== 'string') {
      return ''
    }

    return slugify(children, { lower: true })
  }, [children])

  const anchorPath = useMemo<string>(() => {
    if (typeof location == 'undefined') {
      return ''
    }

    return `${location.pathname}#${niceId}`
  }, [niceId])

  const handleOnAnchorClick = useCallback(
    (anchor: string) => {
      if (onAnchorClick) {
        onAnchorClick(anchor)
      }
    },
    [onAnchorClick]
  )

  switch (element) {
  case 'h1':
    return (
      <h1
        data-anchor={niceId}
        key={niceId}
        className="linked-title linked-title__title"
      >
        {children}
        <Link
          to={anchorPath}
          className="linked-title__anchor"
          onClick={() => handleOnAnchorClick(niceId)}
        >
            #
        </Link>
      </h1>
    )
  case 'h2':
    return (
      <h2
        data-anchor={niceId}
        key={niceId}
        className="linked-title linked-title__title"
      >
        {children}
        <Link
          to={anchorPath}
          className="linked-title__anchor"
          onClick={() => handleOnAnchorClick(niceId)}
        >
            #
        </Link>
      </h2>
    )
  case 'h3':
    return (
      <h3
        data-anchor={niceId}
        key={niceId}
        className="linked-title linked-title__title"
      >
        {children}
        <Link
          to={anchorPath}
          className="linked-title__anchor"
          onClick={() => handleOnAnchorClick(niceId)}
        >
            #
        </Link>
      </h3>
    )
  case 'h4':
    return (
      <h4
        data-anchor={niceId}
        key={niceId}
        className="linked-title linked-title__title"
      >
        {children}
        <Link
          to={anchorPath}
          className="linked-title__anchor"
          onClick={() => handleOnAnchorClick(niceId)}
        >
            #
        </Link>
      </h4>
    )
  case 'h5':
    return (
      <h5
        data-anchor={niceId}
        key={niceId}
        className="linked-title linked-title__title"
      >
        {children}
        <Link
          to={anchorPath}
          className="linked-title__anchor"
          onClick={() => handleOnAnchorClick(niceId)}
        >
            #
        </Link>
      </h5>
    )
  case 'h6':
    return (
      <h6
        data-anchor={niceId}
        key={niceId}
        className="linked-title linked-title__title"
      >
        {children}
        <Link
          to={anchorPath}
          className="linked-title__anchor"
          onClick={() => handleOnAnchorClick(niceId)}
        >
            #
        </Link>
      </h6>
    )
  default:
    throw `Unsupported title element: ${element}`
  }
}

export default LinkedTitleComponent
