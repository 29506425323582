const toUiDateString = (date: number | string | Date): string => {
  const dateInstance = date instanceof Date ? date : new Date(date)

  return dateInstance.toLocaleDateString(undefined, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })
}

const DateHelper = {
  toUiDateString
}

export default DateHelper
